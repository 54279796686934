.dots-animation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: #cacaca;
  border-radius: 50%;
  animation: bounce 1.4s infinite;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

.message-bubble {
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
  background-color: #d1ecf1;
  color: #0c5460;
  word-wrap: break-word; /* Wrap long words */
  overflow-wrap: break-word; /* Break long words if needed */
  max-width: 70%; /* Limits the width of the message bubble */
}
.message-bubble.ai {
  background-color: #e2e3e5;
  color: #383d41;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-8px);
  }
}

.chat-box {
  transition: width 0.5s ease-in-out;
}

.chat-hidden {
  width: 0px;
  overflow: hidden;
}
