/* Medium devices  */
@media (max-width: 767px) {
  .container {
    max-width: 575px;
  }
  header.header-area .logo,
  header.header-area .login-btn {
    max-width: 135px;
  }

  .hero-content h2 {
    font-size: 30px;
  }

  .hero-content p {
    font-size: 18px;
  }

  .login-btn {
    max-width: 150px;
  }

  .hero-area .row {
    flex-direction: column-reverse;
  }

  .col-half {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .title h3 {
    font-size: 20px;
  }

  body {
    font-size: 13px;
  }

  .feature-item p {
    font-size: 13px;
  }

  .col-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

/* small device  */
@media (max-width: 575px) {
  .container {
    max-width: 380px;
  }

  .col-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .header-area .login-btn {
    /* display: none; */
    margin-top: 30px;
  }
  header.header-area .row {
    justify-content: center;
  }
}
