html,
body,
#container {
  height: 100%;
}

.App {
  display: flex;
  height: 100vh;
  overflow: hidden;
  flex-direction: column;
}

.app-container {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.display-linebreak {
  white-space: pre-line;
}

.error-text {
  color: red;
}

.googlebutton {
  padding-top: 0px;
  padding-right: 2px;
  padding-bottom: 10px !important;
  padding-left: 2px;
}

.testcasefailedbody {
  background-color: white;
}

.teamnametxt {
  color: grey;
  font-weight: bold;
}
.joincodetxt {
  color: grey;
  font-weight: bold;
}

.teamcardcontainer {
  border-radius: 25px;
  width: 100%;
  padding-top: 4%;

  padding-right: 4%;
  padding-bottom: 4%;
}

.teamsconatiner {
  border-radius: 25px;
  border: 0.5px solid black;
  margin: auto;
  padding-top: 4%;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 4%;
  height: 70% !important;
}
.logo {
  padding-left: 5%;
  width: 120px;
  padding-top: 12px;
  float: left;
}
.menubar {
  width: 500px;
  float: left;
  padding-left: 10%;
}
.avatardiv {
  width: 80px;
  float: right !important;
  padding-top: 8px;
  padding-right: 25px !important;
}
.header {
  display: flex;
  padding: 2px;
  border-bottom: 1px solid black;
}
.teams {
  margin: auto;
  padding-top: 2% !important;
  padding-left: 2% !important;
  padding-right: 2% !important;
  width: 50%;
}

.alertnewuser {
  float: center;
  padding-top: 2% !important;
  padding-left: 13% !important;
  padding-right: 10% !important;
  width: 80%;
}

.listquestions {
  float: center;
  padding-top: 2% !important;
  padding-left: 13% !important;
  padding-right: 10% !important;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.joinbutton {
  padding-top: 2%;
  padding-bottom: 2%;
}
.joininput {
  width: 80%;
}

.username {
  float: right;
  padding-left: 60px !important;
}

.col {
  flex: 1; /* additionally, equal width */
}

.chatFrom {
  color: blue;
  font-weight: bold;
}
.flexbox-container-header {
}

.flexbox-container {
  display: block;
  height: 100%x;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: rgb(230, 230, 230);
}
.questionBoxContainer {
  height: 700px;
  padding-right: 1%;
  width: 30% !important;
}

.EditorBoxContainer {
  height: 700px;

  width: 40% !important;
}
.chatBoxContainer {
  height: 700px;
  padding-left: 1%;
  float: right;
  width: 30% !important;
}
.questionBoxCss {
  border-radius: 10px;
  overflow: scroll;
  display: block;
  height: 700px;
  float: left;
  margin: auto;
  width: 100%;
  background: rgb(255, 255, 255);
  border: 0px solid black;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 0%;
  padding-bottom: 0%;
}

.EditorBoxCss {
  border-radius: 10px;
  overflow: scroll;
  display: block;
  height: 700px;
  float: center;
  margin: auto;
  width: 100%;
  background: white;
  border: 0px solid black;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 0%;
  padding-bottom: 0%;
}

.ChatBoxCss {
  border-radius: 10px;
  display: block;
  height: 700px;
  float: right;
  margin: auto;
  width: 100%;
  background: white;
  border: 0px solid black;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 0%;
  padding-bottom: 0%;
}
.ChatBoxHeaderCss {
  display: block;
  height: 40px;
}
.ChatBoxDisplayCss {
  display: flex;
  height: 600px;
  overflow: scroll;
  flex-direction: column-reverse;
}
.ChatBoxInputCss {
  padding-top: 5%;
  padding-bottom: 10px;
  padding-right: 10px;
  display: block;
  height: 30;
  bottom: 0;
  position: relative;
}
.h1 {
  text-align: center;
}

.editor-header-menu-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 0.4rem;
}

.icon-button {
  display: flex;
  align-items: center;
  padding-right: 0.6rem;
}

/* page not found component styling */
.pnf-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.pnf-text {
  font-size: 2rem;
  font-weight: bold;
}

.pnf-link {
  color: blue;
  text-decoration: underline;
}

.dashboard-link {
  color: blue;
  text-decoration: underline;
  font-weight: 500;
}

.full-screen-toggle-button {
  display: "flex";
  align-items: "center";
  justify-content: "center";
  padding: 0;
  background: transparent;
  border: 1px solid #1677ff;
  color: #1677ff;
  height: 1.45rem;
  border-radius: 15%;
  :hover {
    padding: 0;
  }
}

.full-screen-icon {
  font-size: 1.2rem !important;
  padding: 0 !important;
  margin: 0 !important;
}

/* Footer styles */
.footer {
  background-color: #fff;
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid #ddd;
  margin-top: 1rem;
  width: 100%;
}

.footer .container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer p {
  margin: 0;
  color: #6c757d;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.footer-links li {
  display: inline;
}

.footer-links a {
  color: #007bff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.error {
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: auto;
}
