.landing-page-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}

/* Header */
.lp-header-site-name {
  display: flex;
  align-items: center;
  padding-left: 2rem;

  @media (max-width: 768px) {
    padding-left: 1rem;
  }

  img {
    height: 2rem;
    width: 2rem;

    @media (max-width: 768px) {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  h1 {
    margin-left: 1rem;
    font-size: 1.5rem;
    color: #000;
    font-weight: 600;
    letter-spacing: 0.1rem;
    font-family: Poppins, sans-serif;
    margin-top: 0.4rem;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
}
.link-button {
  border: none;
  cursor: pointer;
  text-transform: none;
  font-size: 1rem;
  box-shadow: none;
  font-weight: 500;
  color: #4a4a4a;
  border-radius: 0;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-right: 1rem;
  transition: all 0.3s ease-in;
  width: 5rem;

  @media (max-width: 768px) {
    margin: 0 1rem;
    font-size: 0.8rem;
  }
}

.link-button:hover {
  color: #1062fb;
  border-bottom: 2px solid #1062fb;
}

.landing-page-header {
  display: flex;
  min-height: 5rem;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 20;
  transition: all 0.3s ease-in-out;
  @media (max-width: 768px) {
    padding: 0 8px;
    min-height: 3rem;
  }
}

.lp-header-signin-button {
  padding: 0.5rem 1.5rem !important;
  border: none !important;
  cursor: pointer !important;
  text-transform: none !important;
  border-radius: 0.4rem !important;
  font-weight: bold !important;
  font-size: 1rem !important;
  box-shadow: none !important;

  @media (max-width: 768px) {
    padding: 0.2rem 1rem !important;
    font-size: 0.8rem !important;
    text-wrap: nowrap;
    border-radius: 0.2rem !important;
  }
}

/* hero section */
.lp-hs-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #e2ecff;
  padding: 5% 0;
  overflow: hidden;
  gap: 5rem;
}

.lp-hs-content-box {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.lp-hs-header {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem 0 2rem 8%;

  @media (max-width: 768px) {
    width: 90%;
  }

  h1 {
    font-size: 3rem;
    width: 100%;
    margin: 0;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  p {
    font-size: 1rem;
    margin: 1rem 0 2rem 0;
    font-weight: 600;
    color: #5b5b5b;
  }
}

.lp-hs-prime-button {
  padding: 0.5rem 1.2rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  text-transform: none;
  font-size: 1rem;
  font-weight: bold;
  box-shadow: none;
}

.lp-hs-lottie-container {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  margin-right: 8%;
}

.lp-hs-companies {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 1rem 0;
    text-transform: uppercase;
  }
}

.lp-hs-companies-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}

/* Developer section */
.lp-ds-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 0rem 0;
  background-color: #f9f9f9;
}

.lp-ds-content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 8rem 0;
  background-color: #f9f9f9;
  @media (max-width: 768px) {
    padding: 0 0 2rem 0;
  }
}

.lp-ds-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  h4 {
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
    color: #000;
    margin: 1rem 0;
  }

  p {
    text-align: center;
    width: 50%;
    font-weight: 400;
    font-size: 1rem;
    color: #6b6b6b;

    @media (max-width: 768px) {
      width: 90%;
    }
  }
}

.lp-ds-content {
  display: flex;
  gap: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: ".2rem";
  }
}

.lp-ds-editor-container {
  background: #eaeaea;
  width: 40vw;
  border-radius: 0.3rem;
  box-shadow: -2px 4px 20px rgba(0, 0, 0, 0.2);
  @media (max-width: 768px) {
    width: 90vw;
  }
}

.lp-ds-editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 0.8rem 0.2rem 0.8rem;
}

.lp-ds-editor-header-left {
  display: flex;
  align-items: center;
  gap: 0.3rem;

  img {
    vertical-align: middle;
    border-radius: 50%;
  }
}

.lp-ds-editor-language-select {
  width: 6rem;
  font-weight: bold;
  border-radius: 0.3rem;
  box-shadow: none;
  height: 1.8rem;
}

.lp-ds-editor-header-right {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding-bottom: 0.4rem;
}

.lp-ds-questions {
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.lp-ds-question {
  width: 120%;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
  color: #1062fb;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  border-bottom: 1px solid #eaeaea;
  padding: 0.4rem 0.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.lp-ds-output-block {
  background: #fff;
  border-radius: 0.3rem;
  flex-direction: column;
  height: 0rem;
  display: flex;
  transition: transform 0.6s ease;
  z-index: 1;
  border-top: 1px solid #eaeaea;

  h4 {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    color: #333;
  }

  pre {
    background: #eaeaea;
    padding: 1rem;
    border-radius: 0.3rem;
    overflow-x: auto;
    color: #333;
    font-size: 0.9rem;
  }
}

.lp-ds-editor {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 40vw;
  @media (max-width: 768px) {
    width: 90vw;
  }
}

.editor {
  z-index: 2;
}

/* Pricing section */
.lp-pricing-section {
  display: flex;
  padding: 3rem 0;
  align-items: center;
  justify-content: center;
  width: 100%;
}
