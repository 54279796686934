/*
Author: Abdur Rahim
Author URI: https://www.facebook.com/devrahim26/
*/


/* ---------------------- Theme style --------------------------------*/

/* Header styles */
header.header-area {
  padding: 10px 0px;

  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99999999999;
  background: #fff;
  transition: 0.3s;
}
header.header-area.botton-shadow {
  box-shadow: rgb(204 204 204 / 69%) 0px 2px 5px;
}

header.header-area .row {
  align-items: center;
  justify-content: space-between;
}

header.header-area .logo,
header.header-area .login-btn {
  max-width: 200px;
}

/* hero area start */
.hero-area {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.hero-content h2 {
  font-size: 45px;
  line-height: 1.2;
}

.hero-content p {
  font-size: 25px;
  margin: 35px 0;
}

/* hero area end*/

/* how it work area start */

.how-it-work-content {
  max-width: 580px;
  margin: auto;
}

.title h3 {
  font-size: 30px;
  text-align: center;
  margin: 0 0 40px;
  color: #444;
  font-weight: 500;
}

.work-item {
  margin: 0 0 30px;
}

.work-title {
  display: flex;
}

.work-title span {
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  border: 1px solid #ccc;
  float: left;
  margin: 0 16px 0 0;
}

.work-title h4 {
  line-height: 30px;
  color: #333;
  font-weight: 500;
}

.work-content {
  padding: 8px 0 0 45px;
}
/* how it work area end*/

/*  Feature area start */
.feature-item {
  text-align: center;
  margin-bottom: 50px;
}

.feature-item .icon i {
  display: block;
  font-size: 40px;
  text-align: center;
  line-height: 80px;
}

.feature-item .icon {
  width: 80px;
  height: 80px;
  margin: 0 auto 30px;
  border-radius: 6px;
}

.feature-item h5 {
  font-size: 16px;
  margin: 0 0 15px;
  font-weight: 500;
}

.feature-item p {
  font-size: 15px;
}

section.feature-area .row {
  align-items: inherit;
}

.icon.success {
  background: rgb(0 113 0 / 30%);
  color: #007100;
}

.icon.info {
  background: rgb(0 77 201 / 30%);
  color: #004dc9;
}

.icon.denger {
  background: rgb(233 12 20 / 30%);
  color: #e90c14;
}

.icon.grate {
  background: rgb(181 10 190 / 30%);
  color: #b50abe;
}
/*  Feature area end */

/* FAQ start  */
.faq-container.how-it-work-content {
  max-width: 700px;
}
.faq-content.work-content {
  padding: 0;
}

.faq-content.work-content p {
  margin: 5px 0 0 0;
}
.faq-content.work-content p a {
  color: #004dc9;
}
/* FAQ start  */

/* Footer area start */
.footer-logo a {
  display: inline-block;
  max-width: 200px;
}

.footer-logo {
  text-align: center;
}

.footer-logo p {
  margin: 20px 0 0 0;
}
/* Footer area end  */
