/* Default style sheet */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1.5;
  font-family: "Poppins", sans-serif;
  color: #5d6586;
  font-size: 1em;
  font-weight: 400;
}
ol,
ul {
  list-style: none;
  padding: 0;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none !important;
  transition: 0.3s all;
}
span,
a {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #243e8e;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.container {
  max-width: 1170px;
  width: 100%;
  margin: auto;
}

img,
svg {
  max-width: 100%;
  vertical-align: middle;
}

::selection {
  background: #79ffe1;
  color: #000000;
}

.contailer-fluide {
  width: 100%;
  max-width: 100%;
  padding: 0 50px;
}

.gap {
  padding: 80px 0;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.col {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 15px;
}

.col-3 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333333%;
  padding: 0 15px;
}

.col-half {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
}

.h-100 {
  height: 100vh;
}
